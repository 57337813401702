<template>
    <div class="container__en">
        <bannerEn />
    </div>
</template>
<script>
import bannerEn from '../components/home_en/bannerEn.vue'
export default {
    components: {
        bannerEn,
    }
}
</script>
<style scoped></style>